/* GLOBAL */

.interactive-overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
}

.react-date-picker__wrapper {
	border: none !important;
}

/* ======================  Assessment Overview Modal ======================*/

.assessment-dashboard-container {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

/* header section start */
.header-action-container {
	text-align: right;
	padding: 2rem 2rem 2rem 2rem;
	margin: 0px 0px 0px auto;
	background-color: #fff;
}
.assessment-grid-action {
	width: 29px;
	height: 26px;
	color: #e4e4e4;
}

.assessment-ellipse-action {
	width: 7px;
	height: 26px;
	color: #e4e4e4;
	margin-left: 34px;
}

/* header section end */

/* Body start */

.assessment-card-container {
	/* MVP removing cards Un-comment height*/
	/* height: 245px; */
	padding: 2px 2px 2px 2px;
	background-color: #f6f9fd;
	box-sizing: border-box;
}

.assessment-section {
	margin-left: 62px;
	margin-top: 25px;
}

#assign-assessment {
	font-family: 'Montserrat', sans-serif;
	width: 166px;
	height: 35px;
	padding: 2px 2px 2px 2px;
	border-radius: 5px;
	background-color: #37ada7;
	/* box-sizing: border-box; */
	font-weight: 500;
	color: #ffffff;
	text-align: center;
	line-height: normal;
	text-transform: uppercase;
	border-color: #37ada7;
	border: none;
	cursor: pointer;
}

.cards-container {
	display: flex;
	margin-top: 34px;
	gap: 23px;
}

.card {
	width: 240px;
	height: 138px;
	padding: 11px 2px 2px 25px;
	border-radius: 3px;
	border: 1px solid #eaeaea;
	background-color: #ffffff;
	box-sizing: border-box;
	color: #545454;
	text-align: left;
	line-height: normal;
}

.card-header-text {
	margin-bottom: 0;
	font-weight: bold;
	font-size: 20px;
}

.card-body-text {
	margin-top: 0;
	font-weight: bold;
	font-size: 60px;
	margin-bottom: 0px;
}

.assessment-table-container {
	padding: 2px 2px 2px 2px;
	background-color: #ffffff;
	box-sizing: border-box;
	width: 92.5%;
	margin-left: 64px;
	/* For Mvp reducing height. When tiles are present , un-comment the margin-top:55px */
	/* margin-top: 55px; */
	margin-top: 16px;
}

.assessment-table-header-container {
	display: flex;
	justify-content: space-between;
	height: 43px;
}

.table-title {
	padding: 10px;
	font-weight: bold;
}

.icon-color {
	display: inline-block;
	padding-right: 15px;
	padding-top: 10px;
	color: #0c6ee4;
	height: 23px;
}

.assessment-overview-assessment-name {
	cursor: pointer;
}

/* assessment overview react table style */

.assessment_table_body_container .ReactTable .rt-thead.-filters {
	border: none;
	/* hide filter row */
	/* display: none; */
}

.assessment_table_body_container .ReactTable .rt-tr-group {
	background: #fff;
}
.assessment_table_body_container .ReactTable .rt-tr-group:nth-of-type(even) {
	background: #f6f9fd;
}

/* table row data center aligned */
.assessment_table_body_container .ReactTable .rt-tbody {
	text-align: center;
}

.assessment_table_body_container .ReactTable .rt-thead .-header {
	height: 20px;
}

.assessment_table_body_container .ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
	text-align: center;
}
/* assessment overview react table style END*/

.multiple-hover-title {
	color: #24b3bf;
	margin: 0;
	padding: 0;
	cursor: pointer;
	font-weight: bold;
	font-size: 13px;
}

/* Body end */

/* ====================== Assign Assessment Modal ======================*/

.assign-assessment-wrapper {
	box-sizing: border-box;
	height: auto;
	width: 50%;
	position: relative;
	top: 38px;
	left: 46%;
	background-color: #f5f9fd;
	transform: translateX(-50%);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.assign-assessment-modal-container {
	display: block;
	box-sizing: border-box;
	height: auto;
	background-color: #f5f9fd;
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
}

.assign-assessment-modal-header {
	height: 68px;
	background: #fff;
}

.header-content-container {
	display: flex;
	justify-content: space-between;
	padding-top: 15px;
	padding-left: 22px;
	padding-right: 15px;
	border-left: 12px solid #37ada7;
	height: 53px;
}

.assign-assessment-modal-body {
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #f6f9fd;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
	border-top: 1px solid #dfdfdf;
	padding-top: 23px;
}

.assign-assessment-header-close-btn {
	font-size: 25px;
	cursor: pointer;
	font-weight: 200;
}

.assign-assessment-first-text {
	font-family: 'Montserrat', sans-serif;
	font-size: 20px;
	font-weight: bold;
}

.assign-assessment-help-text-container {
	border: 1px solid #86aac7;
	padding: 10px;
	background: #e7f0f7;
	margin-bottom: 16px;
	color:#99b8d0;
}

.assign-assessment-modal-label {
	color: #212121;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
	display: inline-block;
}

.assign-assessment-modal-footer {
    position: relative;
    background-color: white;
    height: 45px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    border-top: 1px solid #dfdfdf;
    padding-top: 15px;
    padding-right: 11px;
}

.assign-assessment-second {
	font-family: 'Montserrat', sans-serif;
	color: #6583a1;
	font-size: 11px;
	line-height: 21px;
	font-weight: bold;
}

.assign-assessment-btn {
	font-family: 'Montserrat', sans-serif;
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	width: 145px;
	height: 30px;
	border-radius: 3px;
	margin-left: 5px;
	font-size: 15px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
}

.assign-assessment-submit-btn {
	font-family: 'Montserrat', sans-serif;
	background-color: #37ADA7;
	border: 1px solid #37ada7;
	cursor: pointer !important;
	width: 180px;
}

.assign-assessment-btn-disabled {
	background-color: #cccccc;
	cursor: not-allowed !important;
	border: none;
}
.assign-assessment-btn-enabled {
	cursor: pointer;
}

.assign-assessment-cancel-button {
	background-color: #ffffff;
	color: #545454;
	border: 2px solid #dadada;
	cursor: pointer !important;
}

.assign-assessment-sub-label {
	font-size: 11px;
	display: block;
	margin-bottom: 7px;
}

.assign-assessment-date-cover {
	border-radius: 3px;
	height: 33px;
	padding-top: 5px;
	padding-left: 10px;
	background-color: #fff;
	border: 1px solid #ffffff;
	box-sizing: border-box;
	box-shadow: 0 0 4px #c4c4c4;
}

.assign-assessment-dropdown {
	box-sizing: border-box;
	height: 40px;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: Montserrat, sans-serif;
	color: #5f6368;
	font-size: 13px;
}

.selected-assessment-name {
	background-color: #f8f8f8;
	border: 1px solid #ddd;
	border-radius: 4px;
	color: #555;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	padding: 5px 25px 5px 10px;
	position: relative;
	width: 95%;
	cursor: pointer;
}

.selected-assessment-name::after {
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	right: 10px;
	margin-top: -3px;
	border-width: 6px 6px 0 6px;
	border-style: solid;
	border-color: #555 transparent;
}
.react-date-picker__wrapper {
	width: 0% !important;
}

.react-date-picker__calendar-button {
	visibility: hidden !important;
}

/* ====================== Assessment Confirmation Modal ======================*/

.assign-assessment-confirmation-modal-container {
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 100%;
	position: absolute;
	top: 0px;
	z-index: 1001;
	background-color: #f3f7fb;
	box-shadow: 0 0 5px #a7a6a6;
	margin-bottom: 10px;
	border-radius: 5px;
	color: "#1B3451";
}

.assign-assessment-confirmation-modal-header {
	border-width: 1px;
	background-color: white;
	border-left-width: 10px;
	border-style: solid;
	border-color: #ededed;
	border-left-color: #11aba6;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	font-weight: 700;
	height: 90px;
	color: #1b3451;
	width: calc(100% - 10px);
}

.assign-assessment-confirmation-header-text {
	font-size: 20px;
	margin-top: 15px;
	margin-left: 30px;
}

.assign-assessment-confirmation-header-close-btn {
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 16px;
	margin-right: 20px;
	font-size: 19px;
	cursor: pointer;
	font-weight: 500;
}

.assign-assessment-confirmation-modal-body {
	position: relative;
	display: block;
	box-sizing: border-box;
	padding: 10px;
	border-radius: 4px;
}
.assign-assessment-confirmation-notes {
	margin: 7px 39px 7px 28px;
	color: #99b8d0;
	font-size: 13px;
	padding: 10px;
	border: 1px solid;
	border-radius: 4px;
	box-sizing: border-box;
	background: #e7f0f7;
	font-weight: 600;
	border-color: #86aac7;
}

.assign-assessment-confirmation-error-box {
	margin: 7px 39px 7px 28px;
	color: #ba5a5a;
	font-size: 13px;
	padding: 10px;
	border: 1px solid #e8a6a6;
	border-radius: 4px;
	box-sizing: border-box;
	background: #eccaca;
	font-weight: 600;
}

.assign-assessment-confirmation-table {
	border-collapse: collapse;
	margin-left: 27px;
	width: 90%;
	border: 5px solid #eaeef2;
	border-spacing: 10px 20px;
}

.assign-assessment-confirmation-thead {
	background-color: #e7f0f7;
	color: #306cdc;
	text-align: left;
}

.assign-assessment-confirmation-first-th {
	height: 50px;
}

.assign-assessment-confirmation-th {
	height: 50px;
	padding-left: 30px;
}

.assign-assessment-confirmation-first-td {
	height: 40px;
	text-align: center;
}

.assign-assessment-td-error {
	color: #f8a9a9;
	font-weight: bolder;
}

.assign-assessment-td-no-error {
	color: black;
	text-align: left;
}

.assign-assessment-confirmation-td {
	padding-left: 30px;
	height: 40px;
	text-align: left;
}

.assign-assessment-confirmation-modal-label {
	color: #212121;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
	display: inline-block;
	margin-left: 27px;
}

.assign-assessment-text-area {
	width: 89%;
	margin-left: 27px;
	resize: none;
	border: 2px solid #eaeef2;
	font-family: 'Montserrat' sans-serif;
}

.assign-assessment-confirmation-footer {
	position: relative;
	padding: 17px;
	background-color: white;
	height: 38px;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
	border-top: 1px solid #dfdfdf;
}

.assign-assessment-confirmation-btn {
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	width: 145px;
	height: 45px;
	border-radius: 3px;
	margin-left: 5px;
	font-size: 15px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
}

.assign-assessment-submit-button {
	background-color: #37ada7;
	border: 1px solid #37ada7;
	cursor: pointer !important;
	width: 180px;
}

.submit-btn-error {
	background-color: #cccccc;
	cursor: not-allowed !important;
	border: none;
}

.submit-btn-no-error {
	cursor: pointer !important;
}

.assign-assessment-conf-cancel-button {
	background-color: #ffffff;
	color: #545454;
	border: 2px solid #dadada;
	cursor: pointer !important;
}

.assign-assessment-conf-loading-container {
	/* style for loading wheelpositioning */
}

/* ====================== Assessment Delete Modal ======================*/

.assessment-delete-modal-wrapper {
	box-sizing: border-box;
	height: auto;
	width: 100%;
	max-width: 600px;
	position: relative;
	top: 38px;
	left: 46%;
	background-color: #f5f9fd;
	transform: translateX(-50%);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.assessment-delete-modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1rem 0;
	gap: 1rem;
}

.warning-text-box {
	font-family: 'Montserrat', sans-serif;
	color: #6583a1;
	font-size: 11px;
	line-height: 21px;
	font-weight: bold;
	border-radius: 3px;
	border: 1px solid rgba(12, 110, 228, 0.15);
	width: 72%;
	padding: 2px 12px 2px 12px;
	background-color: #f6f9fd;
	box-sizing: border-box;
	overflow-y: auto;
	max-height: 609px;
}

.assessment-delete-modal-table-section {
	border-radius: 3px;
	border: 1px solid rgba(12, 110, 228, 0.15);
	width: 72%;
	padding: 2px 12px 2px 12px;
	background-color: #f6f9fd;
	box-sizing: border-box;
	overflow-y: auto;
}

.fw-bold {
	font-weight: bold;
}

.table-body {
    margin-left: 15px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.table-wrapper {
    padding: 2px;
    border-radius: 3px;
    border: 1px solid #e6e2e2;
    background-color: #ffffff;
    box-sizing: border-box;
    text-align: left;
}

.table-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
}

.table-cell {
    flex: 1 0 50%;
    padding: 2px 5px;
    box-sizing: border-box;
}

/* ====================== MODAL =============== */
.employee-edit-modal {
	position: fixed;
	z-index: 1;
	left: 79px;
	top: 250px;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
	font-family: 'Montserrat', sans-serif;
	width: 450px;
	box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	background-color: #f7f7f7;
	color: #2d2d2d;
	border-radius: 10px;
	border-style: solid;
	border-color: #f7f7f7;
	z-index: 10000;
}

/* Modal Content/Box */
.employee-edit-modal-content {
	background-color: #f7f7f8;
	border-radius: 4px;
}

/* Modal Header */
.employee-edit-modal-header {
	width: calc(100% - 15px);
    background-color: #F7F7F7;
    color: #646A7E;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    height: 35px;
    padding-left: 15px;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
}

/* Modal Title */
.employee-edit-modal-title {
	font-size: 18px;
	font-weight: bold;
}

/* Modal Close Button Hover */
.employee-edit-modal-close:hover,
.employee-edit-modal-close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

/* Modal Body */
.employee-edit-modal-body {
	padding: 10px 15px;
	background-color: #F7F7F7;
    color: #646A7E;
    font-size: 18px;
    min-height: 100px;
    text-align: center;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

/* Modal Footer */
.employee-edit-modal-footer {
	background-color: #edf1f2;
	padding: 10px 15px;
	color: #2d2d2d;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	justify-content: flex-end;
	background-color: #EDF1F2;
    color: #2D2D2D;
    height: 50px;
    padding-right: 15px;
    padding-top: 10px;
}

/* Modal Button */
.employee-edit-modal-button {
	display: inline-block;
	box-sizing: border-box;
	background-color: #04aca7;
	color: #ffffff;
	width: 100px;
	height: 35px;
	border-radius: 4px;
	border: 1px solid #37ada7;
	margin-left: 5px;
	font-size: 11px;
	cursor: pointer;
	font-size: 18px;
}

.spinner {
	width: 150px;
	height: 150px;
	border-radius: 100%;
	border-width: 2px;
	border-style: solid;
	border-color: rgb(18, 58, 188) rgb(18, 58, 188) transparent;
	border-image: initial;
	display: inline-block;
	animation: 0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip;
	top: 250px;
	left: 250px;
	position: absolute;
	z-index: 100000;
}
