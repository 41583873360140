/*  ================ Tab css =================== */

.tab-list-item {
	display: inline-block;
	list-style: none;
	padding: 0.5rem 0.75rem;
	font-weight: bolder;
}

.tab-list-active {
	background-color: white;
	border-bottom: #1b3452;
	border-width: 1px 1px 2px 1px;
	color: #1b3452;
	font-weight: 900;
	text-decoration: underline;
	text-underline-offset: 10px;
	text-decoration-thickness: 3px;
	font-weight: bold;
}

/* ====================  AssessmentDetails component ============ */

.assessment-details-modal-wrapper {
	margin: 25px;
}

.assessment-details-modal-container {
	width: 100%;
	background-color: #fff;
}

/* ============  AssessmentHeader component ============ */

.assessment-header-modal-header {
	/* removing height just for now because of tiles . Show when 4 tiles are needed*/
	/* height: 250px; */
	border-radius: 3px;
	border: 1px solid rgba(12, 110, 228, 0.15);
	background-color: #f6f9fd;
	text-align: center;
}

.assessment-header-modal-header-text-pill-container {
	display: flex;
	justify-content: space-between;
	margin: 30px;
}

.assessment-header-modal-header-text {
	width: 343px;
	height: 30px;
	background-color: rgba(255, 255, 255, 0);
	box-sizing: border-box;
	font-weight: bold;
	text-align: left;
	line-height: normal;
}

.assessment-header-modal-header-subtext {
	background-color: rgba(255, 255, 255, 0);
	box-sizing: border-box;
	font-style: italic;
	color: #333333;
	text-align: left;
	line-height: normal;
	font-size: 13px;
	margin-top: 1px;
}

.assessment-header-pills-container {
	width: 117px;
	height: 28px;
	padding: 2px 2px 2px 2px;
	border-radius: 30px;
	box-sizing: border-box;
}

.assessment-header-due-span {
	color: #fff;
	font-weight: bold;
	font-size: 12px;
}

.assessment-header-tiles-container {
	display: flex;
	margin-left: 30px;
	width: 93%;
	justify-content: space-between;
}

.assessment-header-tile-card {
	width: 227px;
	height: 95px;
	background-color: #ffffff;
	border: 2px solid #e3e4e4;
	border-radius: 5px;
	text-align: left;
	padding: 0px;
	padding-left: 37px;
}

.assessment-header-tile-primary-text {
	font-weight: bold;
	font-size: 15px;
	margin-bottom: 0;
}

.assessment-header-tile-secondary-text {
	font-weight: bold;
	font-size: 33px;
	margin: 0;
	padding: 0;
}

.text-pills {
	display: flex;
}
/*  ==============   Assessment Body component =========   */

.assessment-body-tab-panel {
	font-family: DMSans-Medium, "DM Sans Medium", "DM Sans", sans-serif;
	font-weight: 500;
	font-style: normal;
}

/*================ Assessment Attestation Component ================= */

.assessment-attest-wrapper {
	margin-top: 2px;
	margin-left: 1px;
}

.assessment-attest-text-container {
	padding: 2px 2px 2px 2px;
	border-radius: 3px;
	border: 1px solid rgba(12, 110, 228, 0.15);
	background-color: #f6f9fd;
	text-align: center;
	line-height: normal;
	text-align: justify;
}

.assessment-attest-text-header {
	font-size: 13px;
	margin: 15px;
}

.assessment-attest-question-topic-section {
	box-sizing: border-box;
	color: #333333;
	text-align: center;
	line-height: normal;
	display: flex;
	margin-top: 20px;
}

.assessment-attest-question-sections {
	border-radius: 3px;
	border: 1px solid rgba(12, 110, 228, 0.15);
	width: 72%;
	padding: 2px 12px 2px 12px;
	background-color: #f6f9fd;
	box-sizing: border-box;
	overflow-y: auto;
	max-height: 609px;
}

.assessment-attest-topic-name {
	height: 25px;
	background-color: rgba(255, 255, 255, 0);
	box-sizing: border-box;
	font-weight: 700;
	color: #1b3452;
	text-align: left;
	line-height: 25px;
}

.assessment-attest-assignee-info {
	height: 84px;
	padding: 2px 2px 2px 2px;
	border-radius: 3px;
	border: 1px solid #e6e2e2;
	background-color: #ffffff;
	box-sizing: border-box;
	text-align: left;
}

.assessment-attest-table {
	margin-left: 15px;
	margin-top: 5px;
	width: 90%;
	border-collapse: separate;
	border-spacing: 10px;
}

.assessment-attest-table-second-td {
	padding-left: 30px;
}

.assessment-attest-table-td-label {
	font-weight: bold;
}

.assessment-attest-table-td-value {
	margin-left: 20px;
}

.assessment-attest-div-seperator {
	margin-left: 38px;
}

.assessment-attest-topic-sections {
	width: 28%;
	height: 609px;
	padding: 2px 2px 2px 2px;
	border-radius: 3px;
	border: 1px solid rgba(12, 110, 228, 0.15);
	background-color: #f6f9fd;
	box-sizing: border-box;
	color: #333333;
	text-align: center;
	line-height: normal;
}

.assessment-attest-li {
	padding: 2px;
	margin-Left: 43px;
	list-style-type: circle;
	padding-top: 7px;
	font-size: 13px;
}

.assessment-attest-ul {
	padding: 0;
	margin: 0;
}

.assessment-attest-li:hover {
	background-color: transparent;
	color: inherit;
	cursor: default;
}

/*  ===================== TopicQuestion Component ======================== */

.topic-question-section-container {
	width: 100%;
	text-align: left;
	margin-left: 1px;
	margin-top: 29px;
}

.topic-question-section-name {
	height: 25px;
	box-sizing: border-box;
	font-weight: 700;
	color: #1b3452;
	text-align: left;
	line-height: 25px;
}

.topic-question-section-question {
	/* height: 357px; */
	height: auto;
	padding: 2px 2px 2px 2px;
	border-radius: 3px;
	border: 1px solid #e6e2e2;
	background-color: #ffffff;
	box-sizing: border-box;
	margin-bottom: 20px;
	margin-top: 14px;
}

.topic-question-increase-height {
	height: auto;
}

.attachment-alignment {
	display: flex;
	margin-bottom: 15px;
}

.topic-question-container {
	/* height: 319px; */
	width: 90%;
	margin-left: 28px;
	margin-top: 21px;
	/* display: flex; */
}

.topic-question-index {
	font-weight: 700;
	color: #1b3452;
	text-align: left;
	line-height: normal;
}

.topic-question-body {
	margin-left: 33px;
}

.topic-question-questiontext {
	font-weight: 700;
	color: #545454;
	text-align: left;
	line-height: normal;
	word-wrap: break-word;
	width: 100%;
}
.topic-question-questiontitle {
	font-weight: normal;
	color: #545454;
	text-align: left;
	line-height: normal;
	word-wrap: break-word;
	width: 100%;
}

.topic-question-questiontext p {
	margin-top: 0;
	margin-bottom: 0;
}

.text-area-container {
	position: relative;
	margin-bottom: 15px;
}

.topic-question-input-panel {
	width: 100%;
	height: 100px;
	padding: 10px 2px 2px 10px;
	border-radius: 3px;
	border: 1px solid #e6e2e2;
	background-color: #ffffff;
	text-align: left;
	resize: none;
	font-family: 'Montserrat', sans-serif;
}

.topic-question-input-panel:focus {
	outline: none;
}

.btn-text-area {
	font-size: 11px;
	position: absolute;
	right: 0px;
	bottom: 8px;
	cursor: pointer;
}
.btn-text-area-hide {
	visibility: hidden;
}

.topic-question-attachment-panel {
	margin-top: 6px;
}

.topic-question-attachment-text-header {
	font-weight: 700;
	color: #333333;
	text-align: left;
}

.topic-question-attachment-container {
	width: 268px;
	height: 81px;
	display: flex;
}
.topic-question-attachment-icon {
	height: 80px;
	width: 80px;
	border: 1px solid #e6e2e2;
}

.topic-question-attachment-type {
	text-align: center;
	vertical-align: middle;
	line-height: 80px;
	margin-left: 22px;
}

.topic-question-attachment-info {
	margin-left: 30px;
	color: black;
}
.topic-question-attachment-name {
	margin-top: 0px;
	margin-bottom: 3px;
	font-weight: bold;
	font-size: 12px;
}

.topic-question-attachment-date {
	margin-top: 11px;
	margin-bottom: 3px;
	font-size: 12px;
	color: black;
}

.topic-question-download-icon {
	color: black;
	margin-right: 13px;
}

.topic-question-download-text {
	color: black;
	cursor: pointer;
}

.topic-question-radio-container {
	margin-top: 12px;
	display: flex;
	justify-content: space-between;
	width: 35%;
	margin-bottom: 22px;
	color: black;
}

.topic-question-radio-container1 {
	margin-top: 12px;
	display: flex;
	justify-content: space-between;
	width: 35%;
	margin-bottom: 22px;
	color: black;
	margin-left: 145px;
}



/*  ===================== TopicRightPanel Component ======================== */

.topic-rpanel-topic-item {
	display: flex;
	height: 72px;
	padding: 2px 2px 2px 2px;
	border-radius: 3px;
	border: 1px solid #e6e2e2;
	background-color: #ffffff;
	box-sizing: border-box;
	margin-bottom: 15px;
	justify-content: space-between;
	cursor: pointer;
}

.topic-panel-topic-active-item {
	border: 1px solid #1b3452 !important;
}

.topic-rpanel-topic-name {
	text-align: left;
	display: block;
	font-weight: bold;
	padding: 11px 0px 0px 11px;
}

.topic-rpanel-topic-status {
	display: block;
	margin-left: 13px;
	text-align: left;
	font-size: 14px;
	font-style: italic;
	margin-top: 4px;
}

.topic-rpanel-topic-action {
	display: flex;
	margin-top: 15px;
	margin-right: 10px;
}

.topic-rpanel-avatar-icon {
	position: relative;
	background: #f3f7fb;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.topic-status-icon-container {
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #1b3452;
	right: -5px;
	top: 0px;
}

.topic-status-icon-container-icon {
	color: #ffffff;
}

.reviewed-btn {
	font-family: 'Montserrat', sans-serif;
	position: absolute;
	right: 79px;
	font-size: 17px;
	width: 140px;
	border-radius: 4px;
	color: white;
	border-color: #37ada7;
	background-color: #37ada7;
	height: 28px;
	cursor: pointer;
	border-bottom: none;
	border-right: none;
}

.reviewed-btn-hidden {
	visibility: hidden;
}

.topic-rpanel-action-icon {
	margin-left: 15px;
	margin-top: 5px;
}

.topic-rpanel-topic-header {
	margin-left: 10px;
	width: 30%;
	margin-top: 15px;
	display: flex;
	justify-content: space-around;
}

.topic-rpanel-topic-count {
	font-weight: bold;
}

.topic-rpanel-progress-stat {
	background-color: rgba(255, 255, 255, 0);
	color: #838c91;
	text-align: right;
	width: 58%;
	align-items: end;
	margin: 0px 12px 0px auto;
}

.topic-rpanel-progress-bar-container {
	width: 94%;
	margin-left: 20px;
	margin-top: 5px;
}

.topic-rpanel-topic-list-container {
	margin-top: 15px;
	width: 92%;
	margin-left: 24px;
	max-height: 480px;
	overflow-y: auto;
}

/*========== Assessmentcompletionpanel ============ */
.assessment-completion-panel-container {
	padding: 2px 2px 2px 2px;
	box-sizing: border-box;
	margin-top: 10px;
	background-color: #fff;
	border: 1px solid rgba(12, 110, 228, 0.15);
}
.assessment-completion-header {
	width: 422px;
	height: 25px;
	background-color: rgba(255, 255, 255, 0);
	box-sizing: border-box;
	font-weight: 700;
	color: #1b3452;
	text-align: left;
	line-height: 25px;
}

.assessment-completion-comment-label {
	width: 656px;
	height: 19px;
	box-sizing: border-box;
	font-weight: 700;
	color: #545454;
	text-align: left;
	line-height: normal;
}

.assessment-comp-panel {
	margin-left: 18px;
	width: 95%;
}

.comment-input-panel {
	width: 98%;
	height: 100px;
	padding: 10px 2px 2px 10px;
	border-radius: 3px;
	border: 1px solid #e6e2e2;
	background-color: #ffffff;
	text-align: left;
	resize: none;
	font-family: 'Montserrat', sans-serif;
}

.comment-input-panel:focus {
	outline: none;
}

.assessment-completion-attachment-panel {
	text-align: left;
	margin-top: 6px;
}

.assessment-completion-download-image {
	text-align: center;
	vertical-align: middle;
	line-height: 80px;
	margin-left: 16px;
	color: blue;
	font-weight: bold;
	font-size: 19px;
}

.assessment-completion-attachment-helptext {
	color: #babcbe;
	margin-top: 5px;
	font-size: 13px;
}

.assessment-effective-date-panel {
	text-align: left;
}

.assessment-effective-date-panel-header {
	font-weight: bold;
}

.assessment-effective-date-cal-container {
	display: flex;
	width: 55%;
}

.assessment-effective-date-cover {
	width: 45%;
	border-radius: 3px;
	height: 33px;
	padding-top: 5px;
	padding-left: 10px;
	background-color: #fff;
	border: 1px solid #ffffff;
	box-sizing: border-box;
	box-shadow: 0 0 4px #c4c4c4;
}

.date-picker-seperator {
	font-size: 20px;
	font-weight: bold;
	width: 19px;
	margin-left: 34px;
	margin-right: 16px;
}

.panel-footer-container {
	position: relative;
	margin-top: 80px;
}

.panel-footer {
	position: absolute;
	bottom: 0;
	right: 0;
}

.panel-button {
	width: 94px;
	height: 43px;
	margin-left: 1px;
	margin-right: 5px;
	border-radius: 5px;
	font-weight: 500;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
}

.panel-save-btn {
	border: 1px solid #e4e4e4;
	background-color: #ffffff;
	color: #2d2d2e;
}

.panel-cancel-btn {
	border: 1px solid #e4e4e4;
	background-color: #ffffff;
	color: #2d2d2e;
}

.panel-reject-btn {
	color: #ffffff;
	border: 1px solid #ff6161;
	background-color: #ff6161;
}

.panel-certify-btn {
	color: #ffffff;
	border: 1px solid #0c6ee4;
	background-color: #0c6ee4;
}

.panel-save-btn:disabled,
.panel-reject-btn:disabled,
.panel-certify-btn:disabled {
	opacity: 0.5; /* set the opacity to 50% */
	cursor: not-allowed !important; /* set the cursor to "not-allowed" */
	color: #ccc; /* change the text color to gray */
	border: 1px solid #ccc; /* add a gray border */
}

/* ============ confirm modal ================= */
.custom-modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.custom-modal.show {
	display: block;
}

.confirm-modal {
	position: relative;
	width: 550px;
	text-align: left;
	height: auto;
	margin: 10% auto;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.confirm-modal .modal-header {
	padding: 10px;
	padding-left: 20px;
	border-bottom: 3px solid black;
}

.confirm-modal .modal-title {
	font-size: 18px;
	font-weight: bold;
}

.confirm-modal .modal-close {
	float: right;
	cursor: pointer;
}

.confirm-modal .modal-body {
	padding: 10px;
	padding-left: 20px;
	color: #8b8787;
	font-size: 16px;
	margin-bottom: 50px;
}

.confirm-modal .modal-footer {
	text-align: right;
	padding: 19px;
	background-color: #edf1f2;
}

.confirm-modal .modal-btn {
	padding: 5px 10px;
	margin-left: 10px;
	border-radius: 5px;
	cursor: pointer;
	width: 96px;
	height: 32px;
}

.confirm-modal .modal-cancel {
	background-color: #fff;
	color: #000;
	border-color: #d3d3d4;
}

.confirm-modal .modal-confirm {
	background-color: #4dbbb6;
	color: #fff;
	border: none;
}

.modal-overlay-blur {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	filter: blur(5px);
}
